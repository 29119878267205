import React from 'react'
import styled, { css, useTheme } from 'styled-components'

import { useSelector } from '@hozana/redux/hooks'
import { isKindOfApp } from '@hozana/screen/functions/isKindOfApp'
import { useScreen } from '@hozana/screen/useScreen'

import { Div, TDivProps } from 'elements/layout/Div'

import { useBottomOffset } from 'app/hooks/useBottomOffset'
import { isAlertBannerOpen } from 'app/selectors'

type TStyledDivProps = {
  limitHeight?: boolean
  height?: string
  withNavigatorHeader?: boolean
} & TDivProps

const StyledDiv = styled(Div)<TStyledDivProps>`
  ${(props) =>
    props.limitHeight
      ? `
     height: ${props.height};
   `
      : `
     min-height: ${props.height};
   `};

  ${(props) =>
    props.withNavigatorHeader &&
    css`
      @supports (-webkit-appearance: none) {
        ${props.limitHeight
          ? `
         height: ${props.height};
         height: ${props.height.replace('dvh', 'vh')};
       `
          : `
         min-height: ${props.height};
         min-height: ${props.height.replace('dvh', 'vh')};
       `}
      }
    `}
`

type TFullPageDivProps = {
  withoutHeader?: boolean
} & TStyledDivProps

/**
 * ### FullPageDiv ###
 *
 * If the navigator is webkit (Chrome, Safari, Opera) on a mobile device,
 * the '100vh' value also include the header, even when it's visible, so
 * we must substract the navigator header size.
 * See: https://dev.to/wavetree/100vh-behavior-on-chrome-2hm8
 */
export const FullPageDiv: React.FC<TFullPageDivProps> = ({ limitHeight, withoutHeader, ...otherProps }) => {
  const theme = useTheme()
  const screen = useScreen()
  const alertBannerOpen = useSelector((state) => isAlertBannerOpen(state))
  const bottomOffset = useBottomOffset()

  const headerHeight = alertBannerOpen
    ? ` - ${theme.sizes.headerWithAlertBanner[screen.xs ? 'xs' : 'md']}`
    : ` - ${theme.sizes.header}`
  const bottomOffsetCalc = bottomOffset ? ` - ${bottomOffset}px` : ''

  return (
    <StyledDiv
      withNavigatorHeader={screen.isMobile && isKindOfApp()}
      height={`calc(100dvh${!withoutHeader ? headerHeight : ''}${bottomOffsetCalc})`}
      limitHeight={limitHeight}
      {...otherProps}
    />
  )
}

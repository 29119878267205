import React, { MouseEventHandler } from 'react'

import { useRouter } from '@hozana/router'
import { TUrlQueryValue } from '@hozana/router/functions'
import { OS } from '@hozana/screen/constants'
import { QA } from '@hozana/tests/constants'

import { Image, TImageProps } from 'elements/media/Image'
import { Link } from 'elements/text/Link'

import { TLocale } from 'i18n/constants'

import { getAppStoresLinks } from 'modules/home/functions'

const getStoreImage = (os: OS, locale: TLocale) => {
  locale = ['it', 'pt'].includes(locale) ? 'en' : locale
  const img = os === OS.IOS ? 'apple_store' : 'google_play'
  return `/img/app/${img}_${locale}.png`
}

const storeName = {
  [OS.ANDROID]: 'Play Store',
  [OS.IOS]: 'Apple Store',
}

export type TPlayStoreLinkProps = {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  utmMedium: TUrlQueryValue
  os: OS.ANDROID | OS.IOS
} & Pick<TImageProps, 'maxW'>

export const StoreLink: React.FC<TPlayStoreLinkProps> = ({
  onClick,
  maxW = { xs: '175px', md: '200px' },
  utmMedium,
  os,
}) => {
  const { locale } = useRouter()
  const to = getAppStoresLinks(utmMedium, os)

  return (
    <Link data-testid={QA.COMMON.APP_PROMPT_POPIN.STORE_LINK} to={to} onClick={onClick}>
      <Image src={getStoreImage(os, locale)} alt={storeName[os]} maxW={maxW} />
    </Link>
  )
}

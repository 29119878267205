import React from 'react'
import styled, { keyframes } from 'styled-components'

import { AbsoluteDiv, TAbsoluteDivProps } from 'elements/layout/AbsoluteDiv'
import { Div } from 'elements/layout/Div'
import { Image, TImageProps } from 'elements/media/Image'

const rotate = keyframes`
  100% {
    transform: rotate(-360deg);
  }`

export const StyledAbsoluteDiv = styled(AbsoluteDiv)`
  transform-origin: 1750px 1750px;
  animation: ${rotate} 300s linear infinite;
`

export type TLuminionsPlanetProps = TAbsoluteDivProps & Pick<TImageProps, 'src'>

export const LuminionsPlanet: React.FC<TLuminionsPlanetProps> = ({
  src,
  bg = 'transparent' as const,
  top = true,
  ...otherProps
}) => (
  <AbsoluteDiv top={top} bottom left right zIndex="background" align="topCenter" bg={bg} noOverflow {...otherProps}>
    <Div w="1px" m={{ xs: '-50px 0 0', sm: '-20px 0 0', md: '10px 0 0', lg: '30px 0 0', xl: '60px 0 0' }}>
      <StyledAbsoluteDiv left="-1750px" right="-1750px">
        <Image src={src} alt="trans:common:app.lunimions-planet.image-alt" />
      </StyledAbsoluteDiv>
    </Div>
  </AbsoluteDiv>
)

import React from 'react'

import { FixedDiv } from 'elements/layout/FixedDiv'

export const PageGradientBg = () => (
  <FixedDiv top bottom right left zIndex="background">
    <svg id="gradient" xmlns="http://www.w3.org/2000/svg" data-name="Calque 1" viewBox="0 0 512 430">
      <defs>
        <radialGradient cx="66.27%" cy="59.63%" r="50.73%" fx="66.27%" fy="59.63%" id="a">
          <stop offset="0%" stopColor="rgba(210, 234, 255, 0.5)"></stop>
          <stop offset="100%" stopColor="rgba(210, 234, 255, 0)"></stop>
        </radialGradient>
        <radialGradient cx="23.6%" cy="50%" r="45.33%" fx="23.6%" fy="50%" id="b">
          <stop offset="0%" stopColor="rgba(255, 102, 92, 0.1)"></stop>
          <stop offset="100%" stopColor="rgba(255, 102, 92, 0)"></stop>
        </radialGradient>
        <radialGradient cx="63.87%" cy="45.41%" r="27.6%" fx="63.87%" fy="45.41%" id="c">
          <stop offset="0%" stopColor="rgba(255, 152, 226, 0.1)"></stop>
          <stop offset="100%" stopColor="rgba(255, 152, 226, 0)"></stop>
        </radialGradient>
        <radialGradient cx="29.6%" cy="31.31%" r="30.4%" fx="29.6%" fy="31.31%" id="d">
          <stop offset="0%" stopColor="rgba(255, 251, 215, 0.6)"></stop>
          <stop offset="100%" stopColor="rgba(255, 246, 166, 0)"></stop>
        </radialGradient>
      </defs>
      <rect width="100%" height="100%" x="0" y="0" fill="url(#a)"></rect>
      <rect width="100%" height="100%" x="0" y="0" fill="url(#b)"></rect>
      <rect width="100%" height="100%" x="0" y="0" fill="url(#c)"></rect>
      <rect width="100%" height="100%" x="0" y="0" fill="url(#d)"></rect>
    </svg>
  </FixedDiv>
)

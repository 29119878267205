import styled from 'styled-components'

import { TTextProps, Text } from './Text'

export type THProps = TTextProps & {
  size?: '1' | '2' | '3' | '4' | '5' | '6'
}

export const H = styled(Text).attrs<THProps, THProps>(({ as, size }) => ({
  as: as || (('h' + size) as keyof JSX.IntrinsicElements),
}))``

H.defaultProps = {
  block: true,
  bold: true,
  font: 'montserrat',
  size: '1',
}

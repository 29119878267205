import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'

import { selectMaintenanceStatus } from '@hozana/api/selectors'
import { usePrevious } from '@hozana/hooks/usePrevious'
import { useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { GTM } from '@hozana/tracking/gtm'

import { Div } from 'elements/layout/Div'
import { Space } from 'elements/layout/Space'
import { PageGradientBg } from 'elements/ui/PageGradientBg'

import { useTitle } from 'app/hooks/useTitle'
import { Error503Page } from 'app/pages/Error503Page'
import { Head } from 'app/structure/Head'
import { PAGE } from 'routes/constants'

const hozanaShareImage = '/img/hozana_share.jpg'

export type TPageProps = {
  metaTitle?: string
}

const PagesWithoutBg = [
  PAGE.APP_LANDING_PAGE,
  PAGE.TESTIMONIES,
  PAGE.FAQ,
  PAGE.SETTINGS,
  PAGE.GUIDES_LIST,
  PAGE.GUIDE,
  PAGE.STATIC,
] as PAGE[]

export const Page: React.FC<TPageProps> = ({ children, metaTitle }) => {
  const { pathname, locale, replace, asPath } = useRouter() // #router.replace
  const [currentPath] = asPath.split('?') || []
  const prevCurrentPath = usePrevious(currentPath)
  const theme = useTheme()
  const title = useTitle()

  // Track page views
  useEffect(() => {
    if (prevCurrentPath !== currentPath) {
      GTM.pageView(currentPath, title)
    }
  }, [title, prevCurrentPath, currentPath])

  useEffect(() => {
    if (
      ['it', 'pl'].includes(locale) &&
      !(
        [PAGE.HOME, PAGE.GUIDE, PAGE.GUIDES_LIST, PAGE.CONTACT, PAGE.STATIC, PAGE.ERROR_500, PAGE.ERROR] as PAGE[]
      ).includes(pathname)
    ) {
      replace({
        pathname: PAGE.HOME,
      })
    }
  }, [pathname, locale, replace])

  const isMaintenanceStateActive = useSelector((state) => selectMaintenanceStatus(state))

  return (
    <>
      {/* Default Head values */}
      <Head title={metaTitle || title} image={hozanaShareImage} isIndexed />
      {!PagesWithoutBg.includes(pathname) && <PageGradientBg />}
      <Div transition p="0">
        {isMaintenanceStateActive &&
        !([PAGE.PUBLICATION_VIEW, PAGE.GUIDE, PAGE.STATIC, PAGE.HOME, PAGE.ERROR_500, PAGE.ERROR] as PAGE[]).includes(
          pathname,
        ) ? (
          <Error503Page />
        ) : (
          children
        )}
        <Space visible="xs" id="bottom-space" h={theme.sizes.subHeader} />
      </Div>
    </>
  )
}

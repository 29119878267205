import type { NextPage } from 'next'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { createGetStaticProps } from '@hozana/api/server/static'
import { useIsNative } from '@hozana/hooks/useIsNative'
import { getQueryValues } from '@hozana/router/functions'
import { OS } from '@hozana/screen/constants'
import { getOs } from '@hozana/screen/functions/getOs'
import { useScreen } from '@hozana/screen/useScreen'
import { UTM } from '@hozana/tracking/constants'

import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import { Cell } from 'elements/layout/Cell'
import { Div } from 'elements/layout/Div'
import { Row } from 'elements/layout/Row'
import { Space } from 'elements/layout/Space'
import { Icon } from 'elements/media/Icon'
import { H } from 'elements/text/H'
import { P } from 'elements/text/P'
import { Text } from 'elements/text/Text'
import { LuminionsPlanet } from 'elements/ui/LuminionsPlanet'

import { FullPageDiv } from 'app/structure/FullPageDiv'
import { Head } from 'app/structure/Head'
import { Page } from 'app/structure/Page'
import { ICON } from 'config/icons'
import { NAMESPACE } from 'i18n/constants'
import { PAGE } from 'routes/constants'

import { StoreLink } from 'modules/home/components/StoreLink'
import { getAppStoresLinks } from 'modules/home/functions'

const luminionsPlanet = '/img/luminions_planet_yellow.png'
const imageToShare = '/img/praybreakpage_share.jpg'

export const getStaticProps = createGetStaticProps(PAGE.APP_LANDING_PAGE, 'home:app-landing-page.title', undefined, {
  namespaces: [NAMESPACE.HOME],
})

const StyledShadowAbsoluteDiv = styled(AbsoluteDiv)`
  .box-shadow svg {
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.4));
  }
  .text-shadow {
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
`

export const AppLandingPage: NextPage = () => {
  const screen = useScreen()
  const isNative = useIsNative()
  const queryValues = getQueryValues(__CLIENT__ && window.location)
  const utmMedium =
    (Array.isArray(queryValues.utm_medium) ? queryValues.utm_medium[0] : queryValues.utm_medium) ||
    UTM.MEDIUM.APP_LANDING_PAGE

  useEffect(() => {
    if (screen?.xs) {
      const operatingSystemValue = getOs()

      if ([OS.ANDROID, OS.IOS].includes(operatingSystemValue)) {
        window.location.href = getAppStoresLinks(utmMedium, operatingSystemValue)
      }
    }
  }, [screen, utmMedium])

  return (
    <Page>
      <Head
        title="trans:home:app-landing-page.title"
        description="trans:home:app-landing-page.description"
        image={imageToShare}
      />
      <FullPageDiv align="center" noOverflow withoutHeader>
        <LuminionsPlanet src={luminionsPlanet} bg="whiteOrange" />
        <Text fontSize={{ xs: '1em', md: '20px', xl: '24px' }}>
          <StyledShadowAbsoluteDiv top bottom left right column align="middleBetween">
            <Div w="100%" m="4.5vw 0 0" p="0 1em" color="white" align="center">
              <Icon className="box-shadow" name={ICON.FIRE} size="4em" m="0 0 0.1em" />
              <H className="text-shadow" fontSize={{ xs: '2em', md: '2.3em', xl: '2.6em' }}>
                trans:home:app-landing-page.title
              </H>
              <H size="2" className="text-shadow" fontSize="1em">
                trans:home:app-landing-page.subtitle
              </H>
            </Div>
            <Div
              w={{ xs: '100%', sm: '80%', md: '60%' }}
              m={{ xs: '0 0 3em', sm: '0 0 1em' }}
              align="center"
              color="white"
            >
              <Space />
              <Div m="auto">
                <Row gutter="1em" align="middleCenter">
                  <Cell w={{ xs: '100%', sm: '50%' }} align={!screen.xs && !isNative ? 'right' : undefined}>
                    <StoreLink utmMedium={utmMedium} os={OS.IOS} />
                  </Cell>
                  {!isNative && (
                    <Cell w={{ xs: '100%', sm: '50%' }} align={!screen.xs ? 'left' : undefined}>
                      <StoreLink utmMedium={utmMedium} os={OS.ANDROID} />
                    </Cell>
                  )}
                </Row>
              </Div>
              {!screen.xs && (
                <P className="text-shadow" m="0.6em 0" fontSize="1em">
                  trans:home:app-landing-page.description
                </P>
              )}
            </Div>
          </StyledShadowAbsoluteDiv>
        </Text>
      </FullPageDiv>
    </Page>
  )
}
